<template>
  <div class="textmessage">
    <div v-if="detalform == 'aliyun' && Object.keys(ruleForm).length">
      <div class="textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="阿里云AccessKeyId：" prop="sms-aliyun-access_key_id">
            <el-input clearable v-model.trim="ruleForm['sms-aliyun-access_key_id']"></el-input>
          </el-form-item>

          <el-form-item label="阿里云AccessKeySecret:" prop="sms-aliyun-access_key_secret">
            <el-input clearable v-model.trim="ruleForm['sms-aliyun-access_key_secret']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="模板签名：" prop="sms-aliyun-sign_name">
            <el-input clearable v-model.trim="ruleForm['sms-aliyun-sign_name']"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="textmessage_bottom">
        <div class="textmessage_bottom_text">
          <span>短信通知类型配置</span>
        </div>
        <!-- 订单下单提醒部分 -->
        <div class="textmessage_bottom_ttre">
          <div style="margin-bottom:20px;">
            <span>订单下单提醒</span>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" prop="sms-aliyun-order_create-template_name">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-order_create-template_name']"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" prop="sms-aliyun-order_create-template_id">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-order_create-template_id']"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" prop="sms-aliyun-order_create-template_data">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-order_create-template_data']"></el-input>
              <p class="ptp01">例如：模板内容: 您有一个新的订单，订单号：${order}。则只需填写order 注意：目前只支持设置订单号</p>
            </el-form-item>
          </el-form>
        </div>
        <!-- 订单退款提醒部分 -->
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span>订单退款提醒</span>
            <p>例如：模板内容:您有一条新的退款订单，请查看</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" prop="sms-aliyun-order_refund-template_name">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-order_refund-template_name']"></el-input>
            </el-form-item>
            <!-- 发送手机验证码部分 -->
            <el-form-item label="模板ID：" prop="sms-aliyun-order_refund-template_id">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-order_refund-template_id']"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span>发送手机验证码</span>
            <p>例如：模板内容:您的验证码为89757，请勿告知他人</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" prop="sms-aliyun-verification_code-template_name">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-verification_code-template_name']"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" prop="sms-aliyun-verification_code-template_id">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-verification_code-template_id']"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" prop="sms-aliyun-verification_code-template_data">
              <el-input clearable v-model.trim="ruleForm['sms-aliyun-verification_code-template_data']"></el-input>
              <p class="ptp02">例如：模板内容: 您的验证码为${code}，请勿告知他人。则只需填写code</p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- ///////////////////腾讯云腾讯云腾讯云腾讯云腾讯云腾讯云///////////////////////// -->
    <div class="textmessage_bottom_tx" v-if="detalform == 'tencent' && Object.keys(ruleForm).length">
      <div class=" textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="腾讯云AccessKeyId：" :prop="'sms-tencent-access_key_id'">
            <el-input clearable v-model.trim="ruleForm['sms-tencent-access_key_id']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="腾讯云AccessKeySecret:" :prop="'sms-tencent-access_key_secret'">
            <el-input clearable v-model.trim="ruleForm['sms-tencent-access_key_secret']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="腾讯云App_Key:" :prop="'sms-tencent-app_key'">
            <el-input clearable v-model.trim="ruleForm['sms-tencent-app_key']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="腾讯云App_Id:" :prop="'sms-tencent-app_id'">
            <el-input clearable v-model.trim="ruleForm['sms-tencent-app_id']"></el-input>
          </el-form-item>

          <el-form-item label="模板签名：" :prop="'sms-tencent-sign_name'">
            <el-input clearable v-model.trim="ruleForm['sms-tencent-sign_name']"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="textmessage_bottom">
        <div class="textmessage_bottom_text">
          <span>短信通知类型配置</span>
        </div>
        <!-- 订单下单提醒部分 -->
        <div class="textmessage_bottom_ttre">
          <div style="margin-bottom:20px;">
            <span>订单下单提醒</span>
            <p class="papt">例如：模板内容:您有一条新的订单，订单号85757请登录后台商场查看</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-tencent-order_create-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-order_create-template_name']"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" :prop="'sms-tencent-order_create-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-order_create-template_id']"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" :prop="'sms-tencent-order_create-template_data'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-order_create-template_data']"></el-input>
              <p class="ptp01">例如：模板内容: 您有一个新的订单，订单号：${order}。则只需填写order 注意：目前只支持设置订单号</p>
            </el-form-item>
          </el-form>
        </div>
        <!-- 订单退款提醒部分 -->
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span>订单退款提醒</span>
            <p>例如：模板内容:您有一条新的退款订单，请查看</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-tencent-order_refund-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-order_refund-template_name']"></el-input>
            </el-form-item>
            <!-- 发送手机验证码部分 -->
            <el-form-item label="模板ID：" :prop="'sms-tencent-order_refund-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-order_refund-template_id']"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span>发送手机验证码</span>
            <p>例如：模板内容:您的验证码为89757，请勿告知他人</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-tencent-verification_code-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-verification_code-template_name']"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" :prop="'sms-tencent-verification_code-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-verification_code-template_id']"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" :prop="'sms-tencent-verification_code-template_data'">
              <el-input clearable v-model.trim="ruleForm['sms-tencent-verification_code-template_data']"></el-input>
              <p class="ptp02">例如：模板内容: 您的验证码为${code}，请勿告知他人。则只需填写code</p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- ///////////////华为云华为云华为云华为云华为云华为云////////////////// -->

    <div class="textmessage_bottom_hw" v-if="detalform == 'huawei' && Object.keys(ruleForm).length">
      <div class=" textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="华为云AccessKeyId：" :prop="'sms-huawei-access_key_id'">
            <el-input clearable v-model.trim="ruleForm['sms-huawei-access_key_id']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="华为云AccessKeySecret:" :prop="'sms-huawei-access_key_secret'">
            <el-input clearable v-model.trim="ruleForm['sms-huawei-access_key_secret']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="华为云API接入地址:" :prop="'sms-huawei-end_point'">
            <el-input clearable v-model.trim="ruleForm['sms-huawei-end_point']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label="华为云短信通道号:" :prop="'sms-huawei-channel_code'">
            <el-input clearable v-model.trim="ruleForm['sms-huawei-channel_code']"></el-input>
          </el-form-item>

          <el-form-item label="模板签名：" :prop="'sms-huawei-sign_name'">
            <el-input clearable v-model.trim="ruleForm['sms-huawei-sign_name']"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="textmessage_bottom">
        <div class="textmessage_bottom_text">
          <span>短信通知类型配置</span>
        </div>
        <!-- 订单下单提醒部分 -->
        <div class="textmessage_bottom_ttre">
          <div style="margin-bottom:20px;">
            <span>订单下单提醒</span>
            <p>例如：模板内容:您有一条新的订单，订单号85757请登录后台商场查看</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-huawei-order_create-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-order_create-template_name']"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" :prop="'sms-huawei-order_create-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-order_create-template_id']"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" :prop="'sms-huawei-order_create-template_data'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-order_create-template_data']"></el-input>
              <p class="ptp01">例如：模板内容: 您有一个新的订单，订单号：${order}。则只需填写order 注意：目前只支持设置订单号</p>
            </el-form-item>
          </el-form>
        </div>
        <!-- 订单退款提醒部分 -->
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span>订单退款提醒</span>
            <p>例如：模板内容:您有一条新的退款订单，请查看</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-huawei-order-refund-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-order_refund-template_name']"></el-input>
            </el-form-item>
            <!-- 发送手机验证码部分 -->
            <el-form-item label="模板ID：" :prop="'sms-huawei-order_refund-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-order_refund-template_id']"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="textmessage_bottom_ttreon">
          <div style="margin-bottom:20px;">
            <span class="ptp02">发送手机验证码</span>
            <p style="margin-left:12px;;margin-top:6px;">例如：模板内容:您的验证码为89757，请勿告知他人</p>
          </div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="模板名称：" :prop="'sms-huawei-verification_code-template_name'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-verification_code-template_name']"
                class="diy--big"></el-input>
            </el-form-item>

            <el-form-item label="模板ID：" :prop="'sms-huawei-verification_code-template_id'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-verification_code-template_id']"
                class="diy--big"></el-input>
            </el-form-item>

            <el-form-item label="模板变量：" :prop="'sms-huawei-verification_code-template_data'">
              <el-input clearable v-model.trim="ruleForm['sms-huawei-verification_code-template_data']"></el-input>
              <p class="ptp03">例如：模板内容: 您的验证码为${code}，请勿告知他人。则只需填写code</p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <diy-footer-button>
      <el-button type="primary" @click="edit">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
export default {
  props: ['detalform'],
  data() {
    return {
      radio: "1",
      ruleForm: {},
      rules: {
        // 阿里云验证
        'sms-aliyun-access_key_id': [{ required: true, message: "请输入阿里云AccessKeyId", trigger: "blur" }],
        'sms-aliyun-access_key_secret': [{ required: true, message: "请输入阿里云AccessKeySecret", trigger: "blur" }],
        'sms-aliyun-sign_name': [{ required: true, message: "请输入模板签名", trigger: "blur" }],
        'sms-aliyun-order_create-template_name': [{ required: true, message: "请输入阿里云下单模板名称", trigger: "blur" }],
        'sms-aliyun-order_create-template_id': [{ required: true, message: "请输入阿里云下单模板id", trigger: "blur" }],
        'sms-aliyun-order_create-template_data': [{ required: true, message: "请输入阿里云下单模板变量", trigger: "blur" }],
        'sms-aliyun-order_refund-template_name': [{ required: true, message: "请输入阿里云退款模板名称", trigger: "blur" }],
        'sms-aliyun-order_refund-template_id': [{ required: true, message: "请输入阿里云退款模板ID", trigger: "blur" }],
        'sms-aliyun-verification_code-template_name': [{ required: true, message: "请输入阿里云验证码模板名称", trigger: "blur" }],
        'sms-aliyun-verification_code-template_id': [{ required: true, message: "请输入阿里云验证码模板ID", trigger: "blur" }],
        'sms-aliyun-verification_code-template_data': [{ required: true, message: "请输入阿里云验证码模板", trigger: "blur" }],

        //腾讯云验证
        'sms-tencent-access_key_id': [{ required: true, message: "请输入腾讯云AccessKeyId", trigger: "blur" }],
        'sms-tencent-access_key_secret': [{ required: true, message: "请输入腾讯云AccessKeySecret", trigger: "blur" }],
        'sms-tencent-app_id': [{ required: true, message: "请输入腾讯云App_Id", trigger: "blur" }],
        'sms-tencent-app_key': [{ required: true, message: "请输入腾讯云App_Key", trigger: "blur" }],
        'sms-tencent-sign_name': [{ required: true, message: "请输入模板签名", trigger: "blur" }],
        'sms-tencent-order_create-template_name': [{ required: true, message: "请输入腾讯云下单模板名称", trigger: "blur" }],
        'sms-tencent-order_create-template_id': [{ required: true, message: "请输入腾讯云下单模板id", trigger: "blur" }],
        'sms-tencent-order_create-template_data': [{ required: true, message: "请输入腾讯云下单模板变量", trigger: "blur" }],
        'sms-tencent-order_refund-template_name': [{ required: true, message: "请输入腾讯云退款模板名称", trigger: "blur" }],
        'sms-tencent-order_refund-template_id': [{ required: true, message: "请输入腾讯云退款模板ID", trigger: "blur" }],
        'sms-tencent-verification_code-template_name': [{ required: true, message: "请输入腾讯云验证码模板名称", trigger: "blur" }],
        'sms-tencent-verification_code-template_id': [{ required: true, message: "请输入腾讯云验证码模板ID", trigger: "blur" }],
        'sms-tencent-verification_code-template_data': [{ required: true, message: "请输入腾讯云验证码模板", trigger: "blur" }],

        //华为云验证
        'sms-huawei-access_key_id': [{ required: true, message: "请输入华为云AccessKeyId", trigger: "blur" }],
        'sms-huawei-access_key_secret': [{ required: true, message: "请输入华为云AccessKeySecret", trigger: "blur" }],
        'sms-huawei-end_point': [{ required: true, message: "请输入华为云API接入地址", trigger: "blur" }],
        'sms-huawei-channel_code': [{ required: true, message: "请输入华为云短信通道号", trigger: "blur" }],
        'sms-huawei-sign_name': [{ required: true, message: "请输入模板签名", trigger: "blur" }],
        'sms-huawei-order_create-template_name': [{ required: true, message: "请输入华为云下单模板名称", trigger: "blur" }],
        'sms-huawei-order_create-template_id': [{ required: true, message: "请输入华为云下单模板id", trigger: "blur" }],
        'sms-huawei-order_create-template_data': [{ required: true, message: "请输入华为云下单模板变量", trigger: "blur" }],
        'sms-huawei-order_refund-template_name': [{ required: true, message: "请输入华为云退款模板名称", trigger: "blur" }],
        'sms-huawei-order_refund-template_id': [{ required: true, message: "请输入华为云退款模板ID", trigger: "blur" }],
        'sms-huawei-verification_code-template_name': [{ required: true, message: "请输入华为云验证码模板名称", trigger: "blur" }],
        'sms-huawei-verification_code-template_id': [{ required: true, message: "请输入华为云验证码模板ID", trigger: "blur" }],
        'sms-huawei-verification_code-template_data': [{ required: true, message: "请输入华为云验证码模板", trigger: "blur" }],
      }
    }
  },
  created() {
    this.dxswutrtext();
  },
  methods: {
    // 获取短信详情接口
    dxswutrtext() {
      this.$get(this.$apis.swutrtext).then(res => {
        // a.b.c -> a-b-c
        let handleFrom = {}
        Object.keys(res.data).forEach(item => {
          handleFrom[item.split('.').join('-')] = res.data[item]
        })
        this.ruleForm = handleFrom;

        this.$parent.radio = res.data['sms.type'];
      });
    },
    // 修改短信信息
    edit() {
      this.ruleForm["sms.type"] = this.detalform;

      // a-b-c -> a.b.c 
      let responseFrom = {};
      Object.keys(this.ruleForm).forEach(item => {
        responseFrom[item.split('-').join('.')] = this.ruleForm[item]
      })

      this.$put(this.$apis.editswutrtext, { ...responseFrom }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.dxswutrtext()
        } else {
          this.$message({
            message: "修改失败",
            type: "error"
          });
        }
      });
      this.dxswutrtext();
    },
    fbuton() {
      this.$router.push("/setup/setup_home");
    },
    butonalli() {
      this.$router.push("/setup/textmessage/alibaba");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  /deep/.el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  height: 1205px;

  .textmessage_top {
    margin-bottom: 30px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
  }

  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 15px 56px;
    box-sizing: border-box;

    .textmessage_bottom_ttre {
      padding: 20px 20px 16px 20px;
      background: #f9fafc;
      border-radius: 3px;
      margin-bottom: 10px;

      .ptp01 {
        width: 800px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: -6px;
      }

      span {
        font-weight: 500;
        margin-left: 12px;
        font-size: 14px;
      }

      p {
        margin-left: 12px;
        margin-top: 6px;
      }
    }

    .textmessage_bottom_ttreon {
      background: #f9fafc;
      border-radius: 3px;
      padding: 20px 24px 4px 20px;
      margin-bottom: 10px;

      .ptp02 {
        width: 500px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;

        margin-top: -6px;
      }

      span {
        font-weight: 500;
        margin-left: 12px;
        font-size: 14px;
      }

      p {
        margin-left: 12px;
        margin-top: 6px;
      }
    }

    .textmessage_bottom_ttreon:last-of-type {
      background: #f9fafc;
      border-radius: 3px;
      padding: 20px 24px 16px 20px;
      margin-bottom: 50px;
    }

    p {
      width: 448px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    .textmessage_bottom_text {
      margin-bottom: 20px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }

    .iconconte {
      display: flex;
      margin: 20px;
      justify-content: space-around;

      .iconconte_one {
        width: 280px;
        height: 74px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;
        display: flex;
        align-items: center;
        margin: 0 12px;
        cursor: pointer;

        img {
          margin-left: 30px;
          width: 39px;
          height: 43px;
        }

        .iconconte_text {
          margin-left: 33px;

          .Interface {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 5px;
            color: #0052d9;
            cursor: pointer;
          }
        }

        /deep/ .el-radio__label {
          display: none;
        }
      }
    }

    // ::v-deep .el-input__inner {
    //   -webkit-appearance: none;
    //   background-color: #fff;
    //   background-image: none;
    //   border-radius: 4px;
    //   border: 1px solid #dcdfe6;
    //   box-sizing: border-box;
    //   color: #606266;
    //   display: inline-block;
    //   height: 38px;
    //   line-height: 40px;
    //   outline: 0;
    //   padding: 0 15px;
    //   transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    //   width: 100%;
    // }
  }

  .textmessage_bottom_hw {
    /deep/.el-form-item__label {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    height: 1370px;

    .textmessage_top {
      margin-bottom: 30px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
    }

    .textmessage_bottom {
      border-radius: 4px;
      background: #fff;
      padding: 15px 56px;
      box-sizing: border-box;

      .textmessage_bottom_ttre {
        .ptp01 {
          width: 800px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: -6px;
        }

        // height: 500px;
        padding: 20px 24px 16px 20px;
        // height: 310px;
        background: #f9fafc;
        border-radius: 3px;
        margin-bottom: 10px;

        span {
          font-weight: 500;
          margin-left: 12px;
          font-size: 14px;
        }

        p {
          margin-left: 12px;
          margin-top: 6px;
        }
      }

      .textmessage_bottom_ttreon {
        background: #f9fafc;
        border-radius: 3px;
        padding: 20px 24px 4px 20px;
        margin-bottom: 10px;

        .ptp03 {
          width: 500px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: -6px;
        }

        .ptp02 {
          font-weight: 500;
          margin-left: 12px;
          font-size: 14px;
        }

        span {
          font-weight: 500;
          margin-left: 12px;
          font-size: 14px;
        }

        p {
          margin-left: 12px;
          margin-top: 6px;
        }
      }

      .textmessage_bottom_ttreon:last-of-type {
        background: #f9fafc;
        border-radius: 3px;
        padding: 20px 24px 8px 20px;
        margin-bottom: 50px;
      }

      p {
        width: 448px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      span {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }

      .textmessage_bottom_text {
        margin-bottom: 20px;

        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }

      .iconconte {
        display: flex;
        margin: 20px;
        justify-content: space-around;

        .iconconte_one {
          width: 280px;
          height: 74px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddfe6;
          display: flex;
          align-items: center;
          margin: 0 12px;
          cursor: pointer;

          img {
            margin-left: 30px;
            width: 39px;
            height: 43px;
          }

          .iconconte_text {
            margin-left: 33px;

            .Interface {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-left: 5px;
              color: #0052d9;
              cursor: pointer;
            }
          }

          /deep/ .el-radio__label {
            display: none;
          }
        }
      }

      // ::v-deep .el-input__inner {
      //   -webkit-appearance: none;
      //   background-color: #fff;
      //   background-image: none;
      //   border-radius: 4px;
      //   border: 1px solid #dcdfe6;
      //   box-sizing: border-box;
      //   color: #606266;
      //   display: inline-block;
      //   height: 38px;
      //   line-height: 40px;
      //   outline: 0;
      //   padding: 0 15px;
      //   transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      //   width: 100%;
      // }
    }
  }

  .textmessage_bottom_tx {
    /deep/.el-form-item__label {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    height: 1370px;

    .textmessage_top {
      margin-bottom: 30px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
    }

    .textmessage_bottom {
      border-radius: 4px;
      background: #fff;
      padding: 15px 56px;
      box-sizing: border-box;

      .papt {
        margin-left: 12px;
        margin-top: 6px;
      }

      .textmessage_bottom_ttre {
        padding: 20px 24px 16px 20px;
        box-sizing: border-box;
        background: #f9fafc;
        border-radius: 3px;
        margin-bottom: 10px;

        span {
          font-weight: 500;
          margin-left: 12px;
          font-size: 14px;
        }

        .ptp01 {
          width: 800px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: -6px;
        }
      }

      .textmessage_bottom_ttreon {
        background: #f9fafc;
        border-radius: 3px;
        padding: 20px 24px 4px 20px;
        margin-bottom: 10px;

        .ptp02 {
          width: 500px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: -6px;
        }

        span {
          font-weight: 500;
          margin-left: 12px;
          font-size: 14px;
        }

        p {
          margin-left: 12px;
          margin-top: 6px;
        }
      }

      .textmessage_bottom_ttreon:last-of-type {
        background: #f9fafc;
        border-radius: 3px;
        padding: 20px 24px 8px 20px;
        margin-bottom: 50px;
      }

      p {
        width: 448px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      span {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }

      .textmessage_bottom_text {
        margin-bottom: 20px;

        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }

      .iconconte {
        display: flex;
        margin: 20px;
        justify-content: space-around;

        .iconconte_one {
          width: 280px;
          height: 74px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddfe6;
          display: flex;
          align-items: center;
          margin: 0 12px;
          cursor: pointer;

          img {
            margin-left: 30px;
            width: 39px;
            height: 43px;
          }

          .iconconte_text {
            margin-left: 33px;

            .Interface {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-left: 5px;
              color: #0052d9;
              cursor: pointer;
            }
          }

          /deep/ .el-radio__label {
            display: none;
          }
        }
      }

      // ::v-deep .el-input__inner {
      //   -webkit-appearance: none;
      //   background-color: #fff;
      //   background-image: none;
      //   border-radius: 4px;
      //   border: 1px solid #dcdfe6;
      //   box-sizing: border-box;
      //   color: #606266;
      //   display: inline-block;
      //   height: 38px;
      //   line-height: 40px;
      //   outline: 0;
      //   padding: 0 15px;
      //   transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      //   width: 100%;
      // }
    }
  }
}
</style>